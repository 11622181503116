import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../utils/http';
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';

export default function BrandList() {
    const { getAccessTokenSilently } = useAuth0();
    const [brands, setBrands] = useState([]); // Assuming the data you're fetching is to be stored

    useEffect(() => {
        const fetchData = async () => {
          try {
            const token = await getAccessTokenSilently();
            const response = await api.get('/brands', {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            response.data.forEach((brand) => {
                if (brand.imageUrl === null || brand.imageUrl === '') {
                  brand.imageUrl = 'https://placehold.co/60/000000/FFF';
                }
            });

            setBrands(response.data); // Store the fetched data in state
          } catch (error) {
            console.log(error);
          }
        };

        fetchData();
      }, [getAccessTokenSilently]); // Dependency array, re-run the effect when getAccessTokenSilently changes

    return (
        <div>
          <h2 className="text-2xl font-bold tracking-tight mb-8">Your Brands</h2>
          <ul
            role="list"
            className="divide-y divide-gray-200 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
          >
            {brands.map((brand) => (
              <li key={brand.name} className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
                <div className="flex min-w-[60%] gap-x-4">
                  <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={brand.imageUrl} alt="" />
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      <Link to={'/brands/' + brand.id}>
                        <span className="absolute inset-x-0 -top-px bottom-0" />
                        {brand.name}
                      </Link>
                    </p>
                    <div className="space-x-1 md:space-x-5 mt-1 flex text-xs leading-5 text-gray-500">
                      <div className="max-w-[70%]">
                        <p className="mt-1 text-xs leading-5 text-gray-500">{brand.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex shrink-0 items-center gap-x-4">
                  <div className="hidden sm:flex sm:flex-col sm:items-end">
                    {/* social icons */}
                    <div className="grid grid-cols-3 grid-rows-2 gap-3 text-xs mt-3 font-bold">
                      <div className="group z-10">
                        <img src="/static/logos/facebook.svg" alt="Facebook" role="img" className="h-5 w-5 inline" />
                        <span className="mx-2 inline pt-1">100,000,000</span>
                      </div>
                      <div className="group relative z-10">
                        <img src="/static/logos/google-analytics.svg" alt="Google Analytics" role="img" className="h-5 w-5 inline" />
                        <span className="mx-2 inline pt-1">12,300</span>
                      </div>
                      <div className="group z-10">
                        <img src="/static/logos/instagram.svg" alt="Instagram" role="img" className="h-5 w-5 inline" />
                        <span className="mx-2 inline pt-1">12,300</span>
                      </div>
                      <div className="group z-10">
                        <img src="/static/logos/linkedin.svg" alt="LinkedIn" role="img" className="h-5 w-5 inline" />
                        <span className="mx-2 inline pt-1">12,300</span>
                      </div>
                      <div className="group z-10">
                        <img src="/static/logos/mailchimp.svg" alt="Mailchimp" role="img" className="h-5 w-5 inline" />
                        <span className="mx-2 inline pt-1">12,300</span>
                      </div>
                      <div className="group z-10">
                        <img src="/static/logos/plausible.svg" alt="Plausible" role="img" className="h-5 w-5 inline" />
                        <span className="mx-2 inline pt-1">12,300</span>
                      </div>
                      <div className="group z-10">
                        <img src="/static/logos/twitter.svg" alt="X (Twitter)" role="img" className="h-5 w-5 inline" />
                        <span className="mx-2 inline pt-1">12,300</span>
                      </div>
                      <div className="group z-10">
                        <img src="/static/logos/convert-kit.svg" alt="ConvertKit" role="img" className="h-5 w-5 inline" />
                        <span className="mx-2 inline pt-1">12,300</span>
                      </div>
                      <div className="group z-10">
                        <img src="/static/logos/pintrest.svg" alt="Pintrest" role="img" className="h-5 w-5 inline" />
                        <span className="mx-2 inline pt-1">12,300</span>
                      </div>
                      <div className="group z-10">
                        <img src="/static/logos/youtube.svg" alt="YouTube" role="img" className="h-5 w-5 inline" />
                        <span className="mx-2 inline pt-1">12,300</span>
                      </div>
                      <div className="group z-10">
                        <img src="/static/logos/discord.svg" alt="Discord" role="img" className="h-5 w-5 inline" />
                        <span className="mx-2 inline pt-1">12,300</span>
                      </div>
                    </div>
                  </div>
                  <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                </div>
              </li>
            ))}
            <li className="relative flex justify-center gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 text-right align-right bg-opacity-50 text-gray-400">
              <Link to="/brands/create" className="flex w-full h-full items-center justify-center gap-x-4">
                Create new brand
              </Link>
            </li>
          </ul>
        </div>
    );
}