import React, { useEffect, useState, Fragment } from 'react';
import { useLocation } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import api from '../utils/http';
import { useOnboarding, OnboardingStatus } from '../contexts/OnboardingContext';
import { usePageTitle } from '../contexts/PageTitleContext';
import { ChatBubbleLeftEllipsisIcon, TagIcon, UserCircleIcon } from '@heroicons/react/20/solid';
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';
import BrandList from './brands/BrandList';

const activity = [
  {
    id: 1,
    type: 'comment',
    person: { name: 'Eduardo Benz', href: '#' },
    imageUrl:
      'https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. ',
    date: '6d ago',
  },
  {
    id: 2,
    type: 'assignment',
    person: { name: 'Hilary Mahy', href: '#' },
    assigned: { name: 'Kristin Watson', href: '#' },
    date: '2d ago',
  },
  {
    id: 3,
    type: 'tags',
    person: { name: 'Hilary Mahy', href: '#' },
    tags: [
      { name: 'Bug', href: '#', color: 'fill-red-500' },
      { name: 'Accessibility', href: '#', color: 'fill-indigo-500' },
    ],
    date: '6h ago',
  },
  {
    id: 4,
    type: 'comment',
    person: { name: 'Jason Meyers', href: '#' },
    imageUrl:
      'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.',
    date: '2h ago',
  },
]

const brands = [
  {
    name: 'CodeWithStu',
    integrations: {
      "Twitter": 9784450,
      "Instagram": 1200000000,
      "Facebook": 32534534
    },
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam.',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    href: '/brands/codewithstu'
  },
  {
    name: 'CodeWithStu 2',
    integrations: {
      "Twitter": 12,
      "Instagram": 32,
      "Facebook": 45645
    },
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam.',
    imageUrl:
      'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    href: 'brands/codewithstu-test'
  }
]

const Dashboard = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState(null); // Assuming the data you're fetching is to be stored
  const { status } = useOnboarding();
  const { setPageTitle } = usePageTitle();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (status !== OnboardingStatus.Complete) {
          return;
        }

        const token = await getAccessTokenSilently();
        const response = await api.get('/brands', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response.data);
        setData(response.data); // Store the fetched data in state
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [getAccessTokenSilently, status]); // Dependency array, re-run the effect when getAccessTokenSilently changes


  useEffect(() => {
    setPageTitle('Dashboard');
  }, [location]);

  return (
    <>
      <div className="space-y-12">
        <BrandList />
        <div>
          <h2 className="text-2xl font-bold tracking-tight mb-8">Activity Feed</h2>
          <div className="flow-root">
            <ul role="list" className="-mb-8">
              {activity.map((activityItem, activityItemIdx) => (
                <li key={activityItem.id}>
                  <div className="relative pb-8">
                    {activityItemIdx !== activity.length - 1 ? (
                      <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                    ) : null}
                    <div className="relative flex items-start space-x-3">
                      {activityItem.type === 'comment' ? (
                        <>
                          <div className="relative">
                            <img
                              className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                              src={activityItem.imageUrl}
                              alt=""
                            />

                            <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                              <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </div>
                          <div className="min-w-0 flex-1">
                            <div>
                              <div className="text-sm">
                                <a href={activityItem.person.href} className="font-medium text-gray-900">
                                  {activityItem.person.name}
                                </a>
                              </div>
                              <p className="mt-0.5 text-sm text-gray-500">Commented {activityItem.date}</p>
                            </div>
                            <div className="mt-2 text-sm text-gray-700">
                              <p>{activityItem.comment}</p>
                            </div>
                          </div>
                        </>
                      ) : activityItem.type === 'assignment' ? (
                        <>
                          <div>
                            <div className="relative px-1">
                              <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                <UserCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                              </div>
                            </div>
                          </div>
                          <div className="min-w-0 flex-1 py-1.5">
                            <div className="text-sm text-gray-500">
                              <a href={activityItem.person.href} className="font-medium text-gray-900">
                                {activityItem.person.name}
                              </a>{' '}
                              assigned{' '}
                              <a href={activityItem.assigned.href} className="font-medium text-gray-900">
                                {activityItem.assigned.name}
                              </a>{' '}
                              <span className="whitespace-nowrap">{activityItem.date}</span>
                            </div>
                          </div>
                        </>
                      ) : activityItem.type === 'tags' ? (
                        <>
                          <div>
                            <div className="relative px-1">
                              <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                <TagIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                              </div>
                            </div>
                          </div>
                          <div className="min-w-0 flex-1 py-0">
                            <div className="text-sm leading-8 text-gray-500">
                              <span className="mr-0.5">
                                <a href={activityItem.person.href} className="font-medium text-gray-900">
                                  {activityItem.person.name}
                                </a>{' '}
                                added tags
                              </span>{' '}
                              <span className="mr-0.5">
                                {activityItem.tags.map((tag) => (
                                  <Fragment key={tag.name}>
                                    <a
                                      href={tag.href}
                                      className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                    >
                                      <svg
                                        className={tag.color + ' h-1.5 w-1.5'}
                                        viewBox="0 0 6 6"
                                        aria-hidden="true"
                                      >
                                        <circle cx={3} cy={3} r={3} />
                                      </svg>
                                      {tag.name}
                                    </a>{' '}
                                  </Fragment>
                                ))}
                              </span>
                              <span className="whitespace-nowrap">{activityItem.date}</span>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
