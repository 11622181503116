import { Fragment, ReactNode, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { usePageTitle } from '../contexts/PageTitleContext';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';


const user = {
    name: 'Tom Cook',
    email: 'tom@example.com',
    imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}

interface LayoutProps {
    children: ReactNode;
}

function renderNavTop() {
    const classes = 'text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium'
    return (
        <>
            <Link to="/" className={classes}>Dashboard</Link>
            <Link to="/brands" className={classes}>Brands</Link>
            <Link to="/links" className={classes}>Link Shortener</Link>
            <Link to="/linktree" className={classes}>LinkTree</Link>
        </>
    );
}

function renderNavDropdown(handleSignOut: () => void) {
    const classes = 'block rounded-md py-2 px-3 text-base font-medium hover:bg-indigo-500 hover:bg-opacity-75';

    return (
        <>
            <Link to="/profile" className={classes}>Profile</Link>
            <Link to="/notifications" className={classes}>Notifications</Link>
            <Link onClick={handleSignOut} to="#" className={classes}>Sign out</Link>
        </>
    );
}

function renderNavMobileDropdown(handleSignOut: () => void) {
    const classes = 'block rounded-md px-3 py-2 text-base font-medium hover:bg-indigo-500 hover:bg-opacity-75';

    return (
        <>
            <Link to="/profile" className={classes}>Profile</Link>
            <Link to="/notifications" className={classes}>Notifications</Link>
            <Link onClick={handleSignOut} to="#" className={classes}>Sign out</Link>
        </>
    );
}


export default function({ children }: LayoutProps) {
    const { pageTitle } = usePageTitle();
    const { logout, isLoading } = useAuth0();
    const handleSignOut = () => {
        logout({ logoutParams: { returnTo: window.location.origin } });
    };

    useEffect(() => { }, [pageTitle]);

    return (
        <>
            {/*
            This example requires updating your template:

            ```
            <html class="h-full bg-gray-100">
            <body class="h-full">
            ```
          */}
            <div className="min-h-full">
                <div className="bg-indigo-600 pb-32">
                    <Disclosure as="nav" className="border-b border-indigo-300 border-opacity-25 bg-indigo-600 lg:border-none">
                        {({ open }) => (
                            <>
                                <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                                    <div className="relative flex h-16 items-center justify-between lg:border-b lg:border-indigo-400 lg:border-opacity-25">
                                        <div className="flex items-center px-2 lg:px-0">
                                            <div className="flex-shrink-0">
                                                <svg className="h-6 w-10 m-1 fill-logo" viewBox="0 0 104 69" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M68.0642 31.5913L47.9638 2.8932C46.231 0.419229 42.7654 -0.224001 40.29 1.55726C37.8146 3.28904 37.171 6.75259 38.9533 9.22657L59.0537 37.9246C60.7865 40.3986 64.2521 41.0418 66.7275 39.2606C69.2029 37.5288 69.8465 34.0652 68.0642 31.5913Z" />
                                                    <path d="M66.7275 30.3048C64.2521 28.573 60.7865 29.1668 59.0537 31.6407L39.2503 59.943C37.5175 62.4169 38.1116 65.8805 40.5871 67.6123C43.0625 69.3441 46.5281 68.7503 48.2609 66.2763L68.0642 37.9741C69.797 35.5001 69.2029 32.0366 66.7275 30.3048Z" />
                                                    <path d="M63.4104 29.3647H5.83217C2.81215 29.3647 0.336731 31.8387 0.336731 34.8569C0.336731 37.8751 2.81215 40.3491 5.83217 40.3491H63.4104C66.4305 40.3491 68.9059 37.8751 68.9059 34.8569C68.9059 31.8387 66.4305 29.3647 63.4104 29.3647Z" />
                                                    <path d="M102.027 30.948C100.938 29.9584 99.6011 29.3152 98.0663 29.3152H86.7784C85.3427 29.3152 83.8574 29.9584 82.8177 30.948C81.8276 31.9376 81.1345 33.521 81.184 34.9064C81.2335 36.3413 81.7286 37.8257 82.8177 38.8647C83.9069 39.8543 85.2437 40.4976 86.7784 40.4976H98.0663C99.5021 40.4976 100.987 39.8543 102.027 38.8647C103.017 37.8751 103.71 36.2918 103.661 34.9064C103.611 33.4715 103.116 31.9871 102.027 30.948Z" />
                                                    <path d="M82.6692 0.815062C81.2335 1.11194 79.9463 1.85413 79.0551 3.09112L78.461 3.98175L73.9062 10.4141L72.619 12.3438C71.7774 13.5313 71.4308 15.1146 71.6784 16.5C71.9259 17.836 72.7675 19.3699 73.9557 20.112C75.1935 20.9037 76.6787 21.349 78.1144 21.0521C79.5502 20.7553 80.8374 20.0131 81.7286 18.7761L82.3227 17.8855L86.8774 11.4531L88.1646 9.52344C89.0063 8.33594 89.3529 6.75259 89.1053 5.36717C88.8578 4.03123 88.0161 2.49736 86.8279 1.75517C85.5902 0.963503 84.105 0.518185 82.6692 0.815062Z" />
                                                    <path d="M77.7679 48.2164C79.2036 48.5132 80.4908 49.2554 81.382 50.4924L81.9761 51.383L86.5309 57.8153L87.8181 59.7451C88.6597 60.9326 89.0063 62.5159 88.7588 63.9013C88.5112 65.2373 87.6696 66.7711 86.4814 67.5133C85.2437 68.305 83.7584 68.7503 82.3226 68.4534C80.8869 68.1566 79.5997 67.4144 78.7085 66.1774L78.1144 65.2867L73.5597 58.8544L72.2725 56.9247C71.4308 55.7372 71.0843 54.1539 71.3318 52.7685C71.5793 51.4325 72.421 49.8986 73.6092 49.1565C74.8469 48.3648 76.3321 47.9195 77.7679 48.2164Z" />
                                                </svg>
                                            </div>
                                            <div className="hidden lg:ml-10 lg:block">
                                                <div className="flex space-x-4">
                                                    {renderNavTop()}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
                                            <div className="w-full max-w-lg lg:max-w-xs">
                                                <label htmlFor="search" className="sr-only">
                                                    Search
                                                </label>
                                                <div className="relative text-gray-400 focus-within:text-gray-600">
                                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                        <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                                                    </div>
                                                    <input
                                                        id="search"
                                                        className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder="Search coming soon..."
                                                        type="search"
                                                        name="search"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex lg:hidden">
                                            {/* Mobile menu button */}
                                            <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-indigo-600 p-2 text-indigo-200 hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                                                <span className="absolute -inset-0.5" />
                                                <span className="sr-only">Open main menu</span>
                                                {open ? (
                                                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                                ) : (
                                                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                                )}
                                            </Disclosure.Button>
                                        </div>
                                        <div className="hidden lg:ml-4 lg:block">
                                            <div className="flex items-center">
                                                <button
                                                    type="button"
                                                    className="relative flex-shrink-0 rounded-full bg-indigo-600 p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                                                >
                                                    <span className="absolute -inset-1.5" />
                                                    <span className="sr-only">View notifications</span>
                                                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>

                                                {/* Profile dropdown */}
                                                <Menu as="div" className="relative ml-3 flex-shrink-0">
                                                    <div>
                                                        <Menu.Button className="relative flex rounded-full bg-indigo-600 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                                                            <span className="absolute -inset-1.5" />
                                                            <span className="sr-only">Open user menu</span>
                                                            <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            {renderNavDropdown(handleSignOut)}
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Disclosure.Panel className="lg:hidden">
                                    <div className="space-y-1 px-2 pb-3 pt-2">
                                        {renderNavTop()}
                                    </div>
                                    <div className="border-t border-indigo-700 pb-3 pt-4">
                                        <div className="flex items-center px-5">
                                            <div className="flex-shrink-0">
                                                <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                                            </div>
                                            <div className="ml-3">
                                                <div className="text-base font-medium text-white">{user.name}</div>
                                                <div className="text-sm font-medium text-indigo-300">{user.email}</div>
                                            </div>
                                            <button
                                                type="button"
                                                className="relative ml-auto flex-shrink-0 rounded-full bg-indigo-600 p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                                            >
                                                <span className="absolute -inset-1.5" />
                                                <span className="sr-only">View notifications</span>
                                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div className="mt-3 space-y-1 px-2">
                                            {renderNavMobileDropdown(handleSignOut)}
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                    <header className="py-10">
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <h1 className="text-3xl font-bold tracking-tight text-white">{pageTitle}</h1>
                        </div>
                    </header>
                </div>

                <main className="-mt-32">
                    <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
                        <div className="rounded-lg bg-white p-10 py-10 shadow sm:px-6">{children}</div>
                    </div>
                </main>
            </div>
        </>
    )
}
