import React, { Suspense } from 'react';
import Dashboard from './pages/Dashboard';
import { useAuth0, AppState, Auth0Provider } from '@auth0/auth0-react';

import Root from './pages/shared/Root';


import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


import ErrorPage from './pages/shared/ErrorPage';

const defaultRouteProps = {
  errorElement: <ErrorPage />
}

const profileRoute = {
  ...defaultRouteProps,
  path: "profile",
  lazy: async () => await import("./pages/profile/Index"),
};
const brandRoute = {
  path: "brands",
  children: [
    {
      ...defaultRouteProps,
      index: true,
      lazy: async () => await import("./pages/brands/Index"),
    },
    {
      ...defaultRouteProps,
      path: "create",
      lazy: async () => await import("./pages/brands/Create"),
    },
    {
      ...defaultRouteProps,
      path: ":id",
      lazy: async () => await import("./pages/brands/View"),
    },
  ]
};
const linksRoute = {
  path: "links",
  children: [
    {
      ...defaultRouteProps,
      index: true,
      lazy: async () => await import("./pages/links/Index"),
    },
    {
      ...defaultRouteProps,
      path: "create",
      lazy: async () => await import("./pages/links/Create"),
    },
    {
      ...defaultRouteProps,
      path: ":id",
      lazy: async () => await import("./pages/links/View"),
    },
  ]
};
const linktreeRoute = {
  path: "linktree",
  children: [
    {
      ...defaultRouteProps,
      index: true,
      lazy: async () => await import("./pages/linktree/Index"),
    },
    {
      ...defaultRouteProps,
      path: "create",
      lazy: async () => await import("./pages/linktree/Create"),
    },
    {
      ...defaultRouteProps,
      path: ":id",
      lazy: async () => await import("./pages/linktree/View"),
    },
  ]
};

const router = createBrowserRouter([
  {
    ...defaultRouteProps,
    path: "/",
    element: <Root />,
    children: [
      {
        ...defaultRouteProps,
        index: true,
        element: <Dashboard />
      },
      {
        ...defaultRouteProps,
        path: "notifications",
        lazy: async () => await import("./pages/Notifications"),
      },
      profileRoute,
      brandRoute,
      linksRoute,
      linktreeRoute,
    ]
  }
]);


const App = () => {
  return <RouterProvider router={router} />;
};

export default App;