import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../utils/http';
import Loading from '../components/Loading';

export enum OnboardingStatus {
    Unknown,
    Incomplete,
    Complete,
}

interface OnboardingContextType {
    status: OnboardingStatus;
    setStatus: (status: OnboardingStatus) => void;
    isLoading: boolean;
}

const OnboardingContext = createContext<OnboardingContextType | undefined>(undefined);

interface OnboardingProviderProps {
    children: ReactNode;
}

export const OnboardingProvider: React.FC<OnboardingProviderProps> = ({ children }) => {
    const [status, setStatus] = useState<OnboardingStatus>(OnboardingStatus.Unknown);
    const [isComponentLoading, setIsComponentLoading] = useState(true);
    const navigate = useNavigate();
    const { isLoading: isAuthLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (!isAuthLoading && !isAuthenticated) {
            // Trigger login with redirect if not loading and not authenticated
            loginWithRedirect();
            return;
        }

        if (isAuthenticated) {
            const fetchOnboardingStatus = async () => {
                try {
                    const token = await getAccessTokenSilently();
                    const response = await api.get('/profile', {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    if (response.status === 200) {
                        setStatus(OnboardingStatus.Complete);
                    } else if (response.status === 404) {
                        setStatus(OnboardingStatus.Incomplete);
                    }
                } catch (error) {
                    console.error('Error:', error);
                    setStatus(OnboardingStatus.Unknown);
                } finally {
                    setIsComponentLoading(false);
                }
            };

            fetchOnboardingStatus();
        }
    }, [isAuthLoading, isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
        if (status === OnboardingStatus.Incomplete && !isComponentLoading) {
            navigate('/profile');
        }
    }, [status, isComponentLoading, navigate]);

    if (isAuthLoading || isComponentLoading || status === OnboardingStatus.Unknown) {
        return <Loading />
    }

    return (
        <OnboardingContext.Provider value={{ status, setStatus, isLoading: isComponentLoading }}>
            {children}
        </OnboardingContext.Provider>
    );
};

export const useOnboarding = (): OnboardingContextType => {
    const context = useContext(OnboardingContext);
    if (context === undefined) {
        throw new Error('useOnboarding must be used within an OnboardingProvider');
    }
    return context;
};
