import React from 'react';

const Loading = () => {
    return (
        <div className="flex h-screen p-8 shadow-md bg-white">
            <div className="m-auto">
                <h3>Loading...</h3>
            </div>
        </div>
    );
};

export default Loading;