import { Outlet, useNavigate } from 'react-router-dom';

import Layout from '../../components/Layout';
import { PageTitleProvider } from '../../contexts/PageTitleContext';
import { OnboardingProvider } from '../../contexts/OnboardingContext';
import { AppState, Auth0Provider } from '@auth0/auth0-react';

export default function Root() {
    const navigate = useNavigate();
    const redirectCallback = (appState?: AppState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };
    const authConfig = {
        clientId: 'GsFjXlaIjnUi2GoYt0OMPuZZ1LVqm1V0',
        domain: 'auth.invue.io',
        useRefreshTokens: true,
        onRedirectCallback: redirectCallback,
        authorizationParams: {
            audience: 'https://api.invue.io/',
            scope: 'openid email profile offline_access',
            redirect_uri: window.location.origin
        }
    };

    return (
        <Auth0Provider {...authConfig}>
            <PageTitleProvider>
                <OnboardingProvider>
                    <Layout>
                        <Outlet />
                    </Layout>
                </OnboardingProvider>
            </PageTitleProvider>
        </Auth0Provider>
    );
}